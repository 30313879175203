import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allStoreCategory: [],
  allStoreCategoryList: [],
  storeCategoryDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
};
const defaultPath = "/store-categories";

export const fetchAllStoreCategories = createAsyncThunk(
  "fetch_all_storeCategory",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    store = "",
    app = "",
    name = "",
    rate = "",
  }) => {
    try {
      const url = `${defaultPath}/all-categories?page=${page}&pageSize=${pageSize}&search=${search}&store=${store}&app=${app}&name=${name}&rate=${rate}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllStoreCategoriesList = createAsyncThunk(
  "fetch_all_storeCategory_list",
  async () => {
    try {
      const { data } = await API.get(`${defaultPath}/all-categories-list`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createStoreCategory = createAsyncThunk(
  "create_storeCategory",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateStoreCategory = createAsyncThunk(
  "update_storeCategory",
  async ({ categoryId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${categoryId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchStoreCategoryDetails = createAsyncThunk(
  "fetch_storeCategory_details",
  async (categoryId) => {
    try {
      const { data } = await API.post(`${defaultPath}/update/${categoryId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteStoreCategory = createAsyncThunk(
  "delete_storeCategory",
  async (categoryId) => {
    try {
      const { data } = await API.delete(`${defaultPath}/update/${categoryId}`);
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const storeCategorySlice = createSlice({
  name: "storeCategory",
  initialState,
  extraReducers: (builder) => {
    // fetch all store categories
    builder.addCase(fetchAllStoreCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStoreCategories.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStoreCategory = action.payload.allStoreCategories;
        state.page = action.payload.page;
        state.pageSize = action.payload.pageSize;
        state.pages = action.payload.pages;
      }
    });
    // fetch all store categories list
    builder.addCase(fetchAllStoreCategoriesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStoreCategoriesList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStoreCategoryList = action.payload.allCategories;
      }
    });
    // create new store category
    builder.addCase(createStoreCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createStoreCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"create new category store successfully"} />
        );
      }
    });

    // fetch store category details
    builder.addCase(fetchStoreCategoryDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStoreCategoryDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.storeCategoryDetails = action.payload.storeCategory;
      }
    });
    // update store category
    builder.addCase(updateStoreCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoreCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"store category updated successfully"} />
        );
      }
    });
    // update store category
    builder.addCase(deleteStoreCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteStoreCategory.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStoreCategory.map((item) => {
          if (item._id == action.payload.storeCategory._id) {
            item.active = action.payload.storeCategory.active;
            return item;
          }
          return item;
        });
      }
    });
  },
});

export const storeCategoryReducer = storeCategorySlice.reducer;

// export const createStoreCategory = (data) => API.post('/store-categories/create', data)
// export const udpateStoreCategory = (cateId, data) => API.put(`/store-categories/update/${cateId}`, data)
// export const getAllStoreCategories = () => API.post('/store-categories/all-categories')
// export const deleteStoreCategory = (cateId) => API.delete(`/store-categories/update/${cateId}`)
// export const getStoreCategoryDetails = (cateId) => API.post(`/store-categories/update/${cateId}`)

// export const filterStoreCategories = async (page, keyword, store, name, lowRate, highRate, app, website) => {
//     const link = `/store-categories/filter?page=${page}&keyword=${keyword}&storeId=${store}&name=${name}&lowRate=${lowRate}&highRate=${highRate}&app=${app}&website=${website}`
//     const data = await API.post(link)
//     return data;
// }
