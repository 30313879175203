import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allStores: [],
  allStoresList: [],
  storeDetails: {},
  page: 1,
  pages: 1,
  pageSize: 10,
  error: "",
  instantStores: 0,
  instantActiveStores: 0,
  activeStores: 0,
  inactiveStores: 0,
  allStoresCount: 0,
};

const defaultPath = "/store";

export const fetchAllStores = createAsyncThunk(
  "fetch_stores",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    priority = "",
    status = "",
    offer = "",
    affiliation = "",
    expiredHomeOffer = "",
    name = "",
    campaignType = "",
    store = "",
    storeType = 0,
    sort = 0,
  }) => {
    try {
      const url = `${defaultPath}/all-stores?page=${page}&pageSize=${pageSize}&search=${search}&priority=${priority}&status=${status}&offer=${offer}&affiliation=${affiliation}&expiredHomeOffer=${expiredHomeOffer}&name=${name}&campaignType=${campaignType}&store=${store}&storeType=${storeType}&sort=${sort}`;

      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchAllStoresList = createAsyncThunk(
  "fetch_stores_list",
  async () => {
    try {
      const { data } = await API.post(`${defaultPath}/all-store-list`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const createStore = createAsyncThunk(
  "create_stores",
  async (payload) => {
    try {
      const { data } = await API.post(`${defaultPath}/create`, payload);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const fetchStoreDetails = createAsyncThunk(
  "fetch_store_details",
  async (storeId) => {
    try {
      const { data } = await API.post(`${defaultPath}/details/${storeId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

// Define the resetStoreDetails async thunk
export const resetStoreDetails = createAsyncThunk(
  "reset_store_details",
  async () => {
    return {}; // Simply return an empty object to reset the store details
  }
);

export const updateStore = createAsyncThunk(
  "update_store",
  async ({ storeId, payload }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/${storeId}`,
        payload
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteStore = createAsyncThunk("delete_store", async (storeId) => {
  try {
    const { data } = await API.patch(`${defaultPath}/delete/${storeId}`);
    return data;
  } catch (error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
    }
    toast.custom(
      <Danger message={error?.response?.data?.errors || error.message} />
    );
  }
});

export const updateStatus = createAsyncThunk(
  "update_status",
  async (storeId) => {
    try {
      const { data } = await API.patch(`${defaultPath}/status/${storeId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateStoreDeepLinkStatus = createAsyncThunk(
  "update_store_link_status",
  async (storeId) => {
    try {
      const { data } = await API.patch(`${defaultPath}/deep-link/${storeId}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateStoreIsSpecialStatus = createAsyncThunk(
  "update_store_isSpecial_status",
  async ({ storeId, value }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/is-special/${storeId}`,
        { value }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateStorePriority = createAsyncThunk(
  "update_store_priority",
  async ({ storeId, priority }) => {
    try {
      const { data } = await API.put(
        `${defaultPath}/update/priority/${storeId}`,
        { priority }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);
export const updateStoreAutoCheckStatus = createAsyncThunk(
  "update_store_autoCheck_status",
  async (storeId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update/auto-check/${storeId}`
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const makeStoreTrending = createAsyncThunk(
  "make_store_trending",
  async (storeId) => {
    try {
      const { data } = await API.post(`${defaultPath}/trending/create`, {
        storeId,
      });
      return data;
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const removeFromTrendingStore = createAsyncThunk(
  "remove_trending_store",
  async (storeId) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/trending/delete/${storeId}`
      );
      return { ...data, store: storeId };
    } catch (error) {
      if (error?.response?.status == 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState,
  extraReducers: (builder) => {
    // fetch all stores
    builder.addCase(fetchAllStores.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStores.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores = action.payload.allStores;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
        state.instantActiveStores = action.payload.instantActiveStores;
        state.instantStores = action.payload.instantStores;
        state.activeStores = action.payload.activeStores;
        state.inactiveStores = action.payload.inactiveStores;
        state.allStoresCount = action.payload.allStoresCount;
      }
    });
    // fetch all stores list
    builder.addCase(fetchAllStoresList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStoresList.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStoresList = action.payload.allStores;
      }
    });
    // fetch all store details
    builder.addCase(fetchStoreDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchStoreDetails.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.storeDetails = action.payload.storeDetails;
      }
    });
    // create store
    builder.addCase(createStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createStore.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(<Success message={"successfully created new store"} />);
      }
    });
    // update store details
    builder.addCase(updateStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStore.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated store details"} />
        );
      }
    });
    // reset store details
    builder.addCase(resetStoreDetails.fulfilled, (state) => {
      state.storeDetails = {}; // Reset store details to an empty object
    });
    // update store link status
    builder.addCase(updateStoreDeepLinkStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoreDeepLinkStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores.map((item) => {
          if (item._id === action.payload.store._id) {
            item.deepLinkEnable = action.payload.store.deepLinkEnable;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated store deep link status"} />
        );
      }
    });

    //update store status
    builder.addCase(updateStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores.map((item) => {
          if (item._id === action.payload.store._id) {
            item.active = action.payload.store.active;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated store deep link status"} />
        );
      }
    });

    // create new trending store
    builder.addCase(makeStoreTrending.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(makeStoreTrending.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores = state.allStores.map((item) => {
          if (item._id === action.payload.trendingStore.store) {
            return { ...item, trending: true }; // Return updated object
          }
          return item; // Return unchanged object
        });

        toast.custom(
          <Success message={"successfully created new trending store"} />
        );
      }
    });

    // delete trending store
    builder.addCase(removeFromTrendingStore.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(removeFromTrendingStore.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores = state.allStores.map((item) => {
          if (item._id === action.payload.store) {
            return { ...item, trending: false }; // Return updated object
          }
          return item; // Return unchanged object
        });

        toast.custom(
          <Success message={"successfully deleted trending store"} />
        );
      }
    });

    // update store priority
    builder.addCase(updateStorePriority.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStorePriority.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores.map((item) => {
          if (item._id === action.payload.store._id) {
            item.priority = action.payload.store.priority;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated store priority"} />
        );
      }
    });
    // update store is special status
    builder.addCase(updateStoreIsSpecialStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoreIsSpecialStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores.map((item) => {
          if (item._id === action.payload.store._id) {
            item.isSpecial = action.payload.store.isSpecial;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated is special value"} />
        );
      }
    });
    // update store auto check status
    builder.addCase(updateStoreAutoCheckStatus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateStoreAutoCheckStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores.map((item) => {
          if (item._id === action.payload.store._id) {
            item.autoCheck = action.payload.store.autoCheck;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success message={"successfully updated store auto check status"} />
        );
      }
    });
    // delete store status
    builder.addCase(deleteStore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteStore.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allStores.map((item) => {
          if (item._id === action.payload.store._id) {
            item.active = action.payload.store.active;
            return item;
          }
          return item;
        });
        toast.custom(
          <Success
            message={`store ${
              action.payload.store.active ? "restored" : "deleted"
            } successfully   `}
          />
        );
      }
    });
  },
});

export const storeReducer = storeSlice.reducer;

//0 export const createNewStore = (storeData) => API.post('/store/create', storeData)
// export const getAllActiveStores = () => API.post('/store/all-active/stores')
//0 export const getSingleStore = (storeId) => API.get(`/store/${storeId}`)
// export const changeAutoTrackStatus = (storeId) => API.patch(`/store/change/auto-track/${storeId}`)
// export const getAllOffersInStore = (storeId) => API.post(`/store/${storeId}/my-offers`)
// export const changeStorePriority = (storeId, priority) => API.patch(`/store/${storeId}/priority`, { priority })
//0 export const deleteStore = (storeId) => API.patch(`/store/delete/${storeId}`)
//0 export const changeDeepLinkStatus = (storeId) => API.patch(`/store/deep-link/${storeId}`)
//0 export const changeIsSpecialStatus = (storeId, value) => API.patch(`/store/is-special/${storeId}`, { value })
//0 export const getStores = () => API.post(`/store/all-stores`) // access to all
// export const getAllInactiveStores = (page) => API.post(`/store/all-inactive?page=${page}`)
// export const getAllInstantStores = (page) => API.post(`/store/all-instant/active?page=${page}`)
// export const getAllInactiveInstantStores = (page) => API.post(`/store/all-instant/inactive?page=${page}`)
// export const searchStores = (page, keyword) => API.post(`/store/search?page=${page}&keyword=${keyword}`)
//0 export const updateStore = (storeId, data) => API.put(`/store/update/${storeId}`, data)
