import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import Danger from "src/components/alerts/Danger/Danger";
import API from "../../api/api";
import Success from "src/components/alerts/Success/Success";

const initialState = {
  loading: false,
  allMissingCashback: [],
  paymentsDetails: {},
  error: "",
  page: 1,
  pageSize: 10,
  pages: 1,
};

const defaultPath = "/payments/missing";

export const fetchAllMissingCashback = createAsyncThunk(
  "allMissingCashback",
  async ({
    page = 1,
    pageSize = 10,
    search = "",
    startDate = "",
    endDate = "",
    status = "",
    store = "",
    affiliation = "",
  }) => {
    try {
      const url = `${defaultPath}/all-missing-cashback?page=${page}&pageSize=${pageSize}&search=${search}&startDate=${startDate}&endDate=${endDate}&status=${status}&store=${store}&affiliation=${affiliation}`;
      const { data } = await API.get(url);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const trackMissingCashback = createAsyncThunk(
  "trackMissingCashback",
  async (id) => {
    try {
      const { data } = await API.patch(`${defaultPath}/track/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const deleteMissingPayment = createAsyncThunk(
  "deleteMissingPayment",
  async (id) => {
    try {
      const { data } = await API.delete(`${defaultPath}/delete/${id}`);
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateMissingCashbackNotes = createAsyncThunk(
  "updateMissingCashbackNotes",
  async ({ cashbackId, notes }) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-notes/${cashbackId}`,
        { notes }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

export const updateMissingCashbackStatus = createAsyncThunk(
  "updateMissingCashbackStatus",
  async ({ cashbackId, status }) => {
    try {
      const { data } = await API.patch(
        `${defaultPath}/update-status/${cashbackId}`,
        { status }
      );
      return data;
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        sessionStorage.clear();
      }
      toast.custom(
        <Danger message={error?.response?.data?.errors || error.message} />
      );
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  extraReducers: (builder) => {
    // fetch all payments
    builder.addCase(fetchAllMissingCashback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllMissingCashback.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        state.allMissingCashback = action.payload.allMissedCashback;
        state.page = action.payload.page;
        state.pages = action.payload.pages;
        state.pageSize = action.payload.pageSize;
      }
    });
    // forward missing payment
    builder.addCase(trackMissingCashback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(trackMissingCashback.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated track missing cashback"} />
        );
      }
    });

    // forward missing payment
    builder.addCase(updateMissingCashbackNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMissingCashbackNotes.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        toast.custom(
          <Success message={"successfully updated missing cashback notes"} />
        );
      }
    });

    // forward missing payment
    builder.addCase(updateMissingCashbackStatus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateMissingCashbackStatus.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        const updatedCashback = action.payload.missingCashback;

        // Find the item in allMissingCashback by id and update its status
        const index = state.allMissingCashback.findIndex(
          (item) => item._id === updatedCashback._id
        );

        if (index !== -1) {
          state.allMissingCashback[index].status = updatedCashback.status;
        }

        toast.custom(
          <Success message={"successfully updated missing cashback status"} />
        );
      }
    });

    // delete missing payment
    builder.addCase(deleteMissingPayment.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteMissingPayment.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success) {
        const deletedCashbackId = action.payload.missingCashback._id;

        // Remove the item from allMissingCashback by id
        state.allMissingCashback = state.allMissingCashback.filter(
          (item) => item._id !== deletedCashbackId
        );
        toast.custom(
          <Success message={"successfully updated delete missing payment"} />
        );
      }
    });
  },
});

export const missingCashbackReducer = paymentSlice.reducer;
